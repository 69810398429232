@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css");
@import url("https://site-assets.fontawesome.com/releases/v6.0.0/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&family=Rubik&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css");
@import url("./assets/css/style.css");

.top-bar-md {
  height: 40px;
  display: flex;
  background-color: black;
  padding: 8px 15px;
  color: white;
  font-size: 14px;
}

.top-bar-sm {
  height: auto;
  background-color: black;
  padding: 8px 15px;
  color: white;
  font-size: 14px;
}

.aboutus small {
  display: block;
  margin-top: 20px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #f47629;
}

.aboutus h1 {
  font-size: 40px;
  font-family: Poppins, sans-serif;
}

.aboutus p {
  font-size: 16px;
  color: #7b7b7b;
  font-family: "Roboto", sans-serif;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-inner .first {
  background-image: url("./assets/img/banner_1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
}

.carousel-inner .first .slide-content {
  position: absolute;
  padding: 20vh 20vh;
  color: white;
}

.carousel-inner .second .slide-content,
.carousel-inner .third .slide-content {
  position: absolute;
  top: 30%;
  color: white;
  padding: 0px 20px;
}

.carousel-inner .second {
  background-image: url("./assets/img/banner_2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
}

.carousel-inner .third {
  background-image: url("./assets/img/banner_3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
}

/* Only Mid and Large Screen */
@media only screen and (min-width: 768px) {
  .top-bar-sm {
    display: none;
  }
}

/* Only Small Screen */
@media only screen and (max-width: 767px) {
  .top-bar-md {
    display: none;
  }
  .jobappliaction {
    overflow: scroll;
  }
  .carousel-inner .first .slide-content {
    /* Center of Screen */
    position: absolute;
    top: 20%;
    color: white;
    padding: 0px 20px;
  }
  .carousel-inner .second .slide-content p,
  .carousel-inner .third .slide-content p {
    font-size: 14px;
  }
  .carousel-inner .second .slide-content h1,
  .carousel-inner .third .slide-content h1 {
    font-size: 30px;
  }
  .carousel-inner .second .slide-content,
  .carousel-inner .third .slide-content {
    position: absolute;
    top: 20%;
    color: white;
    padding: 0px 20px;
  }
}

.project-card {
  text-align: justify;
}

.contact-image-upload {
  border: 1px solid black;
  padding: 2%;
  border-radius: 5px;
}

.careers-image-upload {
  border: 1px solid black;
  padding: 2%;
  border-radius: 5px;
}
